import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import HowToPay from "./pages/HowToPay";
import HowItWorks from "./pages/HowItWorks";
import Rates from "./pages/Rates";
import Delivery from "./pages/Delivery";
import PromoFollow from "./pages/PromoFollow";
import Profile from "./pages/Profile";

export default function () {
    return (
      <main>
        <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/payments" element={<HowToPay page={"payments"} />} />
            <Route path="/how-to-pay" element={<HowToPay page={"payments"} />} />
            <Route path="/how-it-works" element={<HowItWorks page={"how-it-works"} />} />
            <Route path="/rates" element={<Rates page={"rates"} />} />
            <Route path="/delivery" element={<Delivery page={"delivery"} />} />
            <Route path="/promo/:channel/:resultType" element={<PromoFollow />} />
            <Route path="/promo/:channel" element={<PromoFollow />} />
            <Route path="/*" element={<HowItWorks page={"how-it-works"} />} />
        </Routes>
      </main>
    );
  }