import { Fragment, useEffect, useState } from "react";
import Header from "../../../Components/Header";
import Http from "../../../Utils/Http";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import { CurrencyText, Input } from "../../../Utils/Template";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";

import { VerificationBadge } from 'cleared-verification-client';
import 'cleared-verification-client/dist/styles/no-tailwind.css';

export default function Profile(props) {

  const [customerData, setCustomerData] = useState({});

  useDocumentTitle('Profile');

  useEffect(() => {
    fetchCustomerData();
  }, []);

  // get query string value
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const customerId = urlParams.get('id');

  const fetchCustomerData = async () => {
    const body = {
      customerId,
    };
    const response = await Http.request({ uri: `/public/open/customer-data`, body });
    console.log(response.body);
    if (response.body) {
      setCustomerData(response.body);
    }
  };

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Profile"}></Header>
          <br />
          <Container>
            <div className="">

              <h2 className="text-3xl leading-6 font-bold text-gray-900 text-center lg:text-left">Verification Status</h2>
              <div className='mb-4'>
                If the user has a valid public token associated, and they are identity-verified, you will see verified below. If not, nothing shows, but you can check the console to see activity.
              </div>
              <hr />
              <div className='mt-5 space-y-5'>
                <h3 className='uppercase text-lg'>Public Badge (Identity):</h3>
                {customerData.userPublicToken ? <VerificationBadge customerData={customerData} service={'identity'} /> : null}
              </div>

            </div>
          </Container>
        </div>
      </div>

    </>
  );
}