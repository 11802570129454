import { Link } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Auth from "./routes/Auth";
import Main from "./routes/Main";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Packages from "./routes/Main/pages/Packages";

import './styles/styles.css';

import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Info from "./routes/Info";
import Home from "./routes/Info/pages/Home";
import moment from "moment";
import Http from "./Utils/Http";
import Notifier from "./Utils/Notifier";
import { CurrencyText } from "./Utils/Template";
import ContactCentreChatWidget from "./Utils/ContactCentreChatWidget";

const user = {
  /*name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    '/assets/images/user-128.png',*/
}
const navigationGuest = [
  { name: 'Register', href: '/auth/register', current: false },
  { name: 'Log In', href: '/auth/login', current: false },
  { name: 'Reset Password', href: '/auth/recover', current: false },
  { name: 'Information', href: '/info/how-it-works', current: false },
];

let navigationAuthUser = [
  // { name: `Home`, href: '/', current: false, type: 'textLogo'},
  { name: 'Packages', href: '/packages', current: false },
  { name: 'Addresses', href: '/settings/addresses', current: false },
  { name: 'Account', href: '/settings/account', current: false }
];

if (true || process.env.REACT_APP_ENABLE_VERIFICATIONS || localStorage.getItem("VERIFICATION_FEATURE") === "on") {
  navigationAuthUser.push({ name: 'Verification', href: '/settings/verification', current: false });
}

navigationAuthUser = [...navigationAuthUser,
{ name: 'Payments', href: '/payments/statement', current: false },
{ name: 'Information', href: '/info/how-it-works', current: false },
];

const userNavigationAuthUser = [
  { name: 'Personal Settings', href: '/settings/account' },
  { name: 'Change Password', href: '/settings/password' },
  { name: 'Log Out', href: '/auth/logout' },
];

const userNavigationGuest = [
];

const textLogo = <><img
  className="h-4"
  src={'/assets/images/airship-logo-text-white.png'}
  alt="AirShip Couriers"
/></>;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

let logoImage = ``;

if (true) {
  logoImage = '/assets/images/airship-logo-500.png';
}

export default function App() {

  const [navigation, setNavigation] = useState('');
  const [notification, setNotification] = useState({});
  const [showNotification, setShowNotification] = useState(true);
  const [showRentablesPromo, setShowRentablesPromo] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.setConfig();
    if (!localStorage.HOST_CONFIG) {
      if (window.location.href.indexOf('firstRun') >= 0) {
        setConfigError(true);
      } else {
        setTimeout(() => window.location.assign(window.location.href + '?firstRun=true'), 1000);
      }
    }
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client') {
      setNavigation('AUTHED');
      handleNotifications();
    }
  }, []);

  const handleNotifications = () => {
    let lastNotificationTimeData = localStorage.getItem('lastNotificationTime');
    let lastNotificationTime;
    if (!lastNotificationTimeData) {
      lastNotificationTime = moment();
    } else {
      lastNotificationTime = moment(lastNotificationTimeData);
    }
    let timeDiff = moment().diff(lastNotificationTime, 'minutes');
    if (timeDiff > (window.getConfig('NOTIFICATION_UPDATE_INTERVAL') || 5)) {
      // If notifications have not been checked for the past 15 minutes, start all over; same for new login
      fetchNotifications();
    } else {
      // If notifications have been checked for the past 15 mins, check local storage info to determine if this notification should be shown to this user.
      let currentNotificationData = localStorage.getItem('currentNotification');
      let currentNotification;
      if (currentNotificationData) currentNotification = JSON.parse(currentNotificationData);
      if (!currentNotification) {
        // If nothing is found, fetch notifications again
        fetchNotifications();
      } else {
        console.log(!currentNotification.seen, 'not seen');
        console.log(!currentNotification.empty, 'not empty');
        console.log(!moment().isAfter(moment(currentNotification.expiresAt)), 'not expired');
        if (!currentNotification.seen && !currentNotification.empty && !moment().isAfter(moment(currentNotification.expiresAt))) {
          setNotification(currentNotification);
        }
      }
      console.log(currentNotification);
    }
  };

  const fetchNotifications = async () => {
    return;
    let currentNotificationData = localStorage.getItem('currentNotification');
    let currentNotification;
    if (currentNotificationData) currentNotification = JSON.parse(currentNotificationData);
    let ignoreNotification = false;
    let newNotification = null;
    const response = await Http.request({ uri: `/notifications/fetch`, body: {} });
    if (response.notification) {
      // Notifier(response.notification);
    }
    if (response.body?.notificationId) newNotification = response.body;
    if (newNotification?.notificationId) {
      if (currentNotification) {
        if (currentNotification.notificationId && currentNotification.notificationId === newNotification?.notificationId) {
          // localStorage.setItem('currentNotification', JSON.stringify({expiresAt: '2023-03-10 02:36:20', message: 'It is a test...yes', seen: false, empty: false}));
          if (currentNotification.seen) newNotification.seen = true;
        }
      }
      if (!ignoreNotification) {
        localStorage.setItem('currentNotification', JSON.stringify(newNotification));
      }
      localStorage.setItem('lastNotificationTime', moment().format('YYYY-MM-DD hh:mm:ss'));
    }
  };

  const hideCurrentNotification = n => {
    n.seen = true;
    setNotification(n);
    localStorage.setItem('currentNotification', JSON.stringify(n));
    localStorage.setItem('lastNotificationTime', moment().format('YYYY-MM-DD hh:mm:ss'));
    setShowNotification(false);
  }

  const [configError, setConfigError] = useState(false);

  const oldMenu = false;
  const newMenu = true;

  const [promo, setPromo] = useState({
    active: false,
    balance: 50,
    twitterBalance: 30,
    instagramBalance: 20,
    startDate: moment('June 17, 2023')
  });

  useEffect(() => {
    updateBalance();
  }, [])

  const updateBalance = () => {
    let timeElapsed = moment.unix(moment()).diff(moment.unix(promo.startDate), 'seconds');
    let cents = timeElapsed / 100000000;
    let balance = 50 - cents;
    setPromo({ ...promo, balance, twitterBalance: balance * (3 / 5), instagramBalance: balance * (2 / 5) });
    setTimeout(() => updateBalance(), 50);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {configError && <>
        <h1>Error</h1>
        <p>We encountered an error while attempting to load the app. Please <a href="/?firstRun=true"><strong>try again</strong></a>. If this continues to happen, please contact us.</p>
      </>
      }
      {!configError &&
        <div className="min-h-full pg-bg-img">
          <ContactCentreChatWidget
            channelId={process.env.REACT_APP_CONTACT_CENTRE_CHANNEL_ID}
            channelName={process.env.REACT_APP_CONTACT_CENTRE_CHANNEL_NAME}
            environment={process.env.REACT_APP_CONTACT_CENTRE_ENVIRONMENT}
            scriptHost={process.env.REACT_APP_CONTACT_CENTRE_SCRIPT_HOST}
            version={process.env.REACT_APP_CONTACT_CENTRE_APP_VERSION}
            userData={{ emailAddress: localStorage.userEmail, name: localStorage.userName }}
            authType={'email'}
          />
          {notification.message && showNotification && <div className={`p-3 text-sm bg-${notification.backgroundColour || 'blue-900'} text-${notification.textColour || 'white'} text-center`}>
            {notification.message} {!notification.readOnly && <span className={'pull-right clickable'} onClick={() => hideCurrentNotification(notification)}><XMarkIcon height={'16'} className={'inline'} title={"Hide this notification"} /></span>}
          </div>}
          {promo.active &&
            <div className={'promo-follow'}>
              Support who support yuh. Str8. <a href={"/info/promo/twitter"} className={"follow-link"}>Follow us</a> now and get <span className={'balance'}>US${promo.balance.toFixed(6)}</span> in rewards to pay for your packages.
            </div>
          }
          {navigation !== 'AUTHED' ?
            (showRentablesPromo && <div className={'promo-follow'}>
              <a href={"https://rentables.app/join?utm_source=airship&utm_campaign=interlinking"} className={"font-bold text-yellow-600"} target="_blank">Rentables</a> is launching in Jamaica! Book trusted and verified nannies, plumbers, electricians, etc., or <a href={"https://rentables.app/join?utm_source=airship&utm_campaign=interlinking"} className={"follow-link"} target="_blank">join</a> and offer your own services. <a href={"https://rentables.app/?utm_source=airship&utm_campaign=interlinking"} className={"follow-link"} target="_blank">Learn More</a>.
            </div>) :
            <div className={'promo-follow bg-orange-600 text-sm font-bold'}>
              Registered members of AirShip are now required to be identity-cleared. We will send you an invitation soon to verify your ID online.
            </div>}
          {newMenu && <Disclosure as="nav" className="bg-top-nav shadow">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <a href={"/"}><img
                          className="h-8 w-8"
                          src={logoImage}
                          alt="AirShip Couriers"
                        /></a>
                      </div>
                      <div className={`${isMobile ? 'hidden' : 'block'}`}>
                        <div className="ml-5 flex space-x-4">
                          {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-white text-black'
                                  : 'text-black hover:bg-blue-300 hover:bg-opacity-75',
                                'px-3 py-2 rounded-md text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.type !== 'textLogo' ? item.name : textLogo}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={`${isMobile ? 'hidden' : 'block'}`}>
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={'/assets/images/user.png'} alt="" />&nbsp;<strong>{localStorage.getItem('userName')}</strong>{localStorage.getItem('boxNumber') && <>&nbsp;(BOX-{localStorage.getItem('boxNumber')})</>}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-black hover:bg-blue-300 hover:bg-opacity-75 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-white text-black'
                            : 'text-black hover:bg-blue-300 hover:bg-opacity-75',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-white pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={'/assets/images/user.png'} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-black">{localStorage.getItem('userName')}</div>
                        <div className="text-sm font-medium text-black">{localStorage.getItem('userEmail')}</div>
                        {localStorage.getItem('boxNumber') && <div className="text-sm font-medium text-black">(BOX-{localStorage.getItem('boxNumber')})</div>}
                      </div>{/*
                      <button
                          type="button"
                          className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>*/}
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-black hover:bg-blue-300 hover:bg-opacity-75"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>}
          <BrowserRouter>
            <Routes>
              <Route path="auth/*" element={<Auth />} />
              <Route path="app/*" element={<Main />} />
              <Route path="info/*" element={<Info />} />
              <Route path="via/qr/*" element={<Info />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Main />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </div>
      }
    </>
  );
}